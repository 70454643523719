import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import Err401 from "../../pages/error/error-401"
import { IsNotNull } from '../../controllers/commons/helper';
const jwt = require("jsonwebtoken");
const encrypt = require("../../controllers/encrypt/encrypt");

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles: Roles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let role = null
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      } else {
        if (IsNotNull(localStorage.getItem("authUser"))) {
          if (IsNotNull(JSON.parse(localStorage.getItem("authUser")).token)) {
            let decrypted = encrypt.cryptr_decrypt().decrypt(JSON.parse(localStorage.getItem("authUser")).token)
            let rs_d = jwt.decode(decrypted);
            role = rs_d.role.split(',')
            if (rs_d.exp * 1000 <= Date.now()) {
              localStorage.removeItem("authUser")
            }
          } else {
            localStorage.removeItem("authUser")
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        }
      }
      return (
        <Layout>
          {
            Roles !== undefined && Roles.length > 0 ? Roles.filter(element => role.includes(element)).length > 0 ? <Component {...props} /> : <Err401 /> : <Component {...props} />
          }
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  roles: PropTypes.array
}

export default Authmiddleware
