import React from "react";
import ReactLoading from "react-loading";
import styles from "../../assets/module/common/common.module.css";

export default function Loading() {
  return (
    <div className={`${styles["loading-page"]}`}>
      <ReactLoading type="spin" color="#1976D2" height={100} width={50} />
    </div>
  );
}