import PropTypes from 'prop-types'
import { Fragment, Suspense } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
// import "./assets/scss/theme.scss"
import "./assets/css/SuperResponsiveTableStyle.css"
import AuthVerifyComponent from "../src/controllers/authentications/authverify"

import LoadingPage from "./pages/loading/loading"
import 'tippy.js/dist/tippy.css';
import 'react-circular-progressbar/dist/styles.css';


const App = props => {
  const Layout = VerticalLayout

  // window.console = function () {}

  // let cm = null
  // let shift = false
  // document.addEventListener('contextmenu', event => {
  //   cm = event
  //   event.preventDefault()
  // });
  // document.addEventListener('onmouseup', event => {
  //   event.preventDefault()
  // });

  // document.onkeydown = function (e) {
  //   shift = false
  //   if (e.key === 'F12') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === 'I') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === 'J') {
  //     return false;
  //   }
  //   if (e.ctrlKey && (e.key.toLowerCase() === 'u')) {
  //     return false;
  //   }
  //   if (e.shiftKey) {
  //     shift = e.shiftKey
  //   }
  // }

  // document.onkeyup = function (e) {
  //   shift = false
  // }
  // document.onmouseup = function (e) {

  //   if (shift && (e.button === 2)) {
  //     e.stopPropagation();
  //     e.preventDefault();
  //     return false;
  //   }
  // }

  // document.oncontextmenu = function (e) {
  //   if (e.button == 2) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     return false;
  //   }
  // }

  return (
    <Fragment>
      <Suspense fallback={<LoadingPage />}>
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                roles={route.roles}
                exact
              />
            ))}
          </Switch>
          <AuthVerifyComponent />
        </Router>
      </Suspense>
    </Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
