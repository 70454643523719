import { IsNotNull } from '../../controllers/commons/helper';

import { withRouter,Redirect } from "react-router-dom"
const encrypt = require("../encrypt/encrypt");
const jwt = require("jsonwebtoken");

const AuthVerifyComponent = ({ history }) => {
  history.listen(() => {  // <--- Here you subscribe to the route change
    if (IsNotNull(localStorage.getItem("authUser"))) {
      let tkn = JSON.parse(localStorage.getItem("authUser")).token
      if (IsNotNull(tkn)) {
        let decrypted = encrypt.cryptr_decrypt().decrypt(tkn)
        let rs_d = jwt.decode(decrypted);
        if (rs_d.exp * 1000 < Date.now()) {
          localStorage.clear();
          history.push("/login")
        }
      } else {
        localStorage.clear();
        history.push("/login")
      }
    }
  });
  return <div></div>;
};

export default withRouter(AuthVerifyComponent);