import { IsNotNull, patternCheck } from './helper';
import dayjs from "dayjs";

/** 
  @param {root} troot root this
  @param {Object} e object
  @param {String} ss state style
  @param {String} s1 state who will set the value
  @param {String} s2 state 2 who will set the value
  @param {String} vs style value
  @param {String} v2 value state 2
  @param {Boolean} o have object
  */
const setValueSelect = async (troot, e, ss, s1, s2, vs, v2, o) => {
  let s = troot.state[s1];
  s.label = e.label;
  s.value = e.value;
  o === true ? s.o = e.o : null
  await troot.setState({ [s1]: s, [ss]: '' });
  IsNotNull(ss) ? await troot.setState({ [ss]: vs }) : null
  IsNotNull(s2) ? await troot.setState({ [s2]: v2 }) : null
}

const setValueSelectCustom = async (troot, e, ss, s1, s2, vs, v2, o) => {
  let s = troot.state[s1];
  s = e
  troot.setState({ [s1]: s, [ss]: '' });
  IsNotNull(ss) ? await troot.setState({ [ss]: vs }) : null
  IsNotNull(s2) ? await troot.setState({ [s2]: v2 }) : null
}

/**
  @param {root} troot root this
  @param {String} sd style default
  @param {String} se style when error
  @param {String} rqx regex pattern
  @param {String} em error message. null if no message
  @param {String} s1 state who will set the value
  @param {String} ss state style who will change
  @param {String} sem state error message who will change
  @param {Boolean} krwe keep running when error true/false
  @param {Component} tnotif component notification
*/
const setValue = async (troot, value, sd, se, rgx, em, s1, ss, sem, krwe, tnotif) => {
  let msg = '';
  let style = sd;
  let v_temp = '';
  let cr = patternCheck(value, rgx);
  if (!cr && value.length > 0) {
    msg = em
    style = se
    v_temp = krwe ? value : troot.state[s1]
    IsNotNull(tnotif) ? tnotif(em, { variant: "error", autoHideDuration: 1200 }) : null
  } else {
    v_temp = value;
    msg = ''
    style = sd;
  }
  IsNotNull(s1) ? await troot.setState({ [s1]: v_temp }) : null
  IsNotNull(sem) ? await troot.setState({ [sem]: msg }) : null
  IsNotNull(ss) ? await troot.setState({ [ss]: style }) : null
}

const setDateValue = async (troot, date, dateString, eos, dayjsUnit, s1) => {
  IsNotNull(date) ? await troot.setState({ [s1]: dayjs(date)[IsNotNull(eos) && eos === 's'?'startOf':'endOf'](dayjsUnit) }) : null
}

export {
  setValueSelect,
  setValue,
  setDateValue,
  setValueSelectCustom
}