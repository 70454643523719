// import React from "react";

export default function error401() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">401</h1>
        <p className="fs-3"> <span className="text-danger">Opps!</span> Unauthorized.</p>
        <p className="lead">
          You Unauthorized to Access This!!!
        </p>
      </div>
    </div>
  );
}