import { IsNotNull } from '../../controllers/commons/helper';
const jwt = require("jsonwebtoken");
const {cryptr_decrypt, cryptr_encrypt} = require("../encrypt/encrypt");
const authControl = require("../auth/auth");
const { compress, decompress } = require('shrink-string')
var decrypt_rs, encrypt_jwt_token, user
const checkAuth = () => {
  var [p1, p2, p3] = authControl.loadAuth()
  decrypt_rs = p1
  encrypt_jwt_token = p2
  user = p3
}

const logout = (history) => {
  localStorage.removeItem("authUser")
  history.push('/login');
}

const login = async (b, history) => {
  var result = { code: '', status: false };
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "login"
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        // Authorization: 'wef23fecver3gw4f3ww2qfw243rf',
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      },
      body: JSON.stringify({
        data: cryptr_encrypt().encrypt(JSON.stringify({
          user: b.p1 !== null ? b.p1: user,
          pass: b.p2
        }))
      }),
    })
      .then(response => response.json())
      .then(async x => {
        if (IsNotNull(x.status) ? x.status : false) {
          if (IsNotNull(x.token)) {
            let decompressed = await decompress(x.token)
            const decrypted = cryptr_decrypt().decrypt(decompressed);
            let rs_d = jwt.decode(decrypted);
            let j = JSON.stringify({
              user: rs_d.user,
              token: decompressed
            })
            result = { code: x.code, status: true }
            localStorage.setItem("authUser", j)
            let exp = ((rs_d.exp * 1000) - Date.now())
            setTimeout(() => {
              logout(history)
            }, exp);
            history.push("/dashboard");
          }
        } else {
          result = { code: x.code, status: false }
        }
      });
  } catch (e) {
    result = { code: '', status: false }
  } finally{
    return result
  }
}

export { login }

