require('dotenv').config();
const Cryptr = require('../commons/crypter/cryptr').default;

const encrypter = new Cryptr(process.env.REACT_APP_ENCRYPT_C_SECRET,
  {
    pbkdf2Iterations: parseInt(process.env.REACT_APP_ENCRYPT_ITERATIONS),
    saltLength: parseInt(process.env.REACT_APP_ENCRYPT_SALT)
  });

const decrypter = new Cryptr(process.env.REACT_APP_ENCRYPT_S_SECRET,
  {
    pbkdf2Iterations: parseInt(process.env.REACT_APP_ENCRYPT_ITERATIONS),
    saltLength: parseInt(process.env.REACT_APP_ENCRYPT_SALT)
  });

const encrypter_c_att = new Cryptr(process.env.REACT_APP_ENCRYPT_C_ATT_SECRET,
  {
    pbkdf2Iterations: parseInt(process.env.REACT_APP_ENCRYPT_ITERATIONS_ATT),
    saltLength: parseInt(process.env.REACT_APP_ENCRYPT_SALT_ATT)
  });

const cryptr_encrypt = () => {
  return encrypter
}
const cryptr_decrypt = () => {
  return decrypter
}
const cryptr_encrypt_att = () => {
  return encrypter_c_att
}

export {
  cryptr_encrypt,
  cryptr_decrypt,
  cryptr_encrypt_att
}