import { Component, Fragment } from "react";
import styles from "../../../assets/module/modal/modal.module.css";
import commstyles from "../../../assets/module/common/common.module.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from "react-draggable";
import SweetAlert from "react-bootstrap-sweetalert";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { setValue } from '../../../controllers/commons/setValue';
import { IsNotNull, checkValue } from '../../../controllers/commons/helper';

const UserController = require('../../../controllers/admins/users');
const LoginController = require('../../../controllers/authentications/login')

class ModalChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
    this.reset = this.reset.bind(this);
  }

  getInitialState() {
    return {
      backdrop: 'static',
      error_message: '',
      v_confirm_pwd: "",
      v_new_pwd: "",
      s_i_confirm_pwd: "form-control",
      s_i_new_pwd: "form-control",
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      showPass: false,
      showPass2: false,
    }
  }

  setConfirmPassword = (e) => {
    setValue(this, e.target.value, 'form-control', "form-control-err", /^.{1,16}$/, null, 'v_confirm_pwd', 's_i_confirm_pwd', null, false, null)
    if (e.target.value !== this.state.v_new_pwd && e.target.value.length > 0 && this.state.v_new_pwd !== "") {
      this.setState({ s_i_confirm_pwd: 'form-control-err', error_message: `Confirm passwords didn't match` });
    }
    else {
      this.setState({ s_i_confirm_pwd: 'form-control', error_message: '' });
    }
  }

  setNewPwd = (e) => {
    setValue(this, e.target.value, 'form-control', "form-control-err", /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-.:;<=>?@[\]^_`{|}).]).{8,16}$/,
      'Password must be 8-16 characters and include letters, numbers and special characters', 'v_new_pwd', 's_i_new_pwd', 'error_message', false, null)
    if (e.target.value !== this.state.v_confirm_pwd && e.target.value.length > 0 && this.state.v_confirm_pwd !== "") {
      this.setState({ s_i_new_pwd: 'form-control-err' });
    }
  }

  reset = () => {
    this.setState(this.getInitialState());
  }

  save = async () => {
    if (this.check()) {
      var b = {
        p1: null,
        p2: this.state.v_new_pwd
      }
      await UserController.resetPwd(b).then(async x => {
        if (x.status) {
          switch (x.code) {
            case "U-01":
              // this.setState({ dynamic_title: "Reset Success" });
              // this.setSuccessDlg();
              await LoginController.login(b, this.props.props.history).then(x => {
                if (IsNotNull(x.status) ? !x.status : true) {
                  this.setState({ dynamic_title: "Relogin Failed" });
                  this.setSuccessDlg();
                } else {
                  this.setState({ dynamic_title: "Relogin Success" });
                  this.setSuccessDlg();
                }

              })

              break;
          }
        } else {
          this.setState({ dynamic_title: "Reset Error" });
          this.setErrorDlg();
        }
      })
    }
  }

  closeAlert = () => {
    this.setState({
      success_dlg: false,
      error_dlg: false,
    });
    this.props.toggleHandler();
  }

  setSuccessDlg = () => {
    this.setState({ success_dlg: !this.state.success_dlg });
  }

  setErrorDlg = () => {
    this.setState({ error_dlg: !this.state.error_dlg });
  }

  check() {
    var rs = true;
    rs = checkValue(this, rs, this.state.v_confirm_pwd, 's_i_confirm_pwd', null, 'v')
    rs = checkValue(this, rs, this.state.v_new_pwd, 's_i_new_pwd', null, 'v')
    return rs;
  }

  setShowPass = (e) => {
    this.setState({ showPass: !this.state.showPass });
  }
  setShowPass2 = (e) => {
    this.setState({ showPass2: !this.state.showPass2 });
  }

  enterHandle = (e) => {
    if (e.key === 'Enter') {
      this.save()
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <Fragment>
        <SnackbarProvider anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} />
        <Draggable handle=".drag">
          <Modal
            isOpen={this.props.modal}
            toggle={this.props.toggleHandler}
            className={`${styles["modal-w-30"]}`}
            onClosed={this.reset}
            backdrop={this.state.backdrop}>
            <ModalHeader toggle={this.props.toggleHandler} className={`${styles["lbl_modal"]} drag`}>Change Password</ModalHeader>
            <ModalBody>
              <div>
                <div className={`${styles["dv-pass"]} ${styles["flex"]} `}>
                  <label className={styles["lbl_modal"]} >New Password</label>
                  <div className={`${commstyles['col-1']}`}>
                    <div className={`${commstyles['password']} ${commstyles['col-1']}`}>
                      {
                        this.state.showPass ? <i className={`fa fa-eye-slash ${commstyles['icon-eye']}`} onClick={e => this.setShowPass(e)}></i> :
                          <i className={`fa fa-eye ${commstyles['icon-eye']}`} onClick={e => this.setShowPass(e)}></i>
                      }

                      <input
                        className={`${styles[this.state.s_i_new_pwd]}`}
                        id="new-pwd"
                        placeholder="Enter Password"
                        type={this.state.showPass ? "text" : "password"}
                        onChange={this.setNewPwd}
                        maxLength={16}
                        onKeyUp={e => this.enterHandle(e)}
                      />
                    </div>
                  </div>

                </div>

                <div className={styles["dv-pass"]}>
                  <label className={styles["lbl_modal"]} >Confirm Password</label>
                  <div className={`${commstyles['password']} ${commstyles['col-1']}`}>
                    {
                      this.state.showPass2 ? <i className={`fa fa-eye-slash ${commstyles['icon-eye']}`} onClick={e => this.setShowPass2(e)}></i> :
                        <i className={`fa fa-eye ${commstyles['icon-eye']}`} onClick={e => this.setShowPass2(e)}></i>
                    }

                    <input
                      className={`${styles[this.state.s_i_confirm_pwd]}`}
                      id="confirm-pwd"
                      placeholder="Enter Password"
                      type={this.state.showPass2 ? "text" : "password"}
                      onChange={this.setConfirmPassword}
                      maxLength={16}
                      onKeyUp={e => this.enterHandle(e)}
                    />
                  </div>
                </div>
                <label className={commstyles["error_msg_comm"]} >{this.state.error_message}</label>
              </div>
            </ModalBody>
            <ModalFooter className="drag">
              <button className={`${styles["btn-primary"]} ${styles["btn"]}`} onClick={this.save}>Save</button>
              <button className={`${styles["btn-secondary"]} ${styles["btn"]}`} onClick={this.props.toggleHandler}>Cancel</button>
            </ModalFooter>
            {
              this.state.success_dlg ? (
                <SweetAlert
                  success
                  title={this.state.dynamic_title}
                  onConfirm={() => {
                    this.closeAlert()
                  }}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null
            }

            {
              this.state.error_dlg ? (
                <SweetAlert
                  error
                  title={this.state.dynamic_title}
                  onConfirm={() => {
                    this.setErrorDlg()
                  }}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null
            }
          </Modal>

        </Draggable>

      </Fragment>
    )
  }
}

export default ModalChangePassword;